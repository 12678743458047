<template>
  <div :class="cn('relative max-h-64 rounded-md border bg-white', props.class)" ref="containerRef">
    <UiTable dir="ltr">
      <UiTableHeader class="px-0">
        <UiTableRow class="min-md:min-h-0 flex-nowrap pb-2.5 pt-3 text-xs">
          <UiTableHead class="h-auto py-0" :style="{ flex: cellsFlex.rank }">Rank</UiTableHead>
          <UiTableHead class="h-auto py-0" :style="{ flex: cellsFlex.name }">Name</UiTableHead>
          <UiTableHead class="h-auto py-0 text-end" :style="{ flex: cellsFlex.performance }"
            >Performance</UiTableHead
          >
        </UiTableRow>
      </UiTableHeader>

      <UiTableBody>
        <UiTableRow
          class="min-md:min-h-0 flex-nowrap border-none hover:bg-neutral-200"
          v-for="(stock, index) in mappedStocks"
          :key="index"
          :to="
            stock.locked
              ? undefined
              : { name: 'stocks-portfolios-details-symbol', params: { symbol: stock.symbol } }
          "
        >
          <UiTableCell class="min-w-8 py-2 pe-0 font-medium" :style="{ flex: cellsFlex.rank }">
            #{{ index + 1 }}
          </UiTableCell>

          <UiTableCell class="py-2" :style="{ flex: cellsFlex.name }">
            <UiInfo
              class="z-1"
              :loading="!isFetched"
              :title="stock.title"
              :subtitle="stock.subtitle"
              :skeletons-repeat="2"
              has-image
              :image-size="24"
              clamp
              :classes="{
                image: 'size-6 border bg-white',
                wrapper: 'gap-3',
                title: 'text-xs/4 font-semibold',
                subtitle: 'line-clamp-1 text-xs/4',
                titlesWrapper: 'flex-col gap-0',
                skeleton: {
                  container: 'gap-1',
                  line: 'h-3 first:w-8',
                },
              }"
              :image="stock.logo"
            >
              <template v-if="stock.locked && isFetched" #image>
                <IconLockIcon class="size-6 rounded border bg-white p-1.5" />
              </template>
            </UiInfo>
          </UiTableCell>

          <UiTableCell class="py-2" :style="{ flex: cellsFlex.performance }">
            <UiSkeleton
              :loading="!isFetched"
              :repeat="2"
              :classes="{
                slot: 'flex flex-col items-end text-xs/4 font-semibold',
                line: 'h-3 w-8',
                container: 'w-fit gap-2 self-end',
              }"
            >
              <span>{{ stock.locked ? "Price" : toCash(stock.allocation) }}</span>
              <span class="text-success" v-if="stock.locked">Change</span>
              <UiPositiveNegative
                class="whitespace-pre font-semibold"
                v-else
                :value="stock.priceChange"
                :format="toPercent"
              />
            </UiSkeleton>
          </UiTableCell>
        </UiTableRow>
      </UiTableBody>
    </UiTable>

    <div
      class="z-1 absolute bottom-0 h-16 w-full scale-110 bg-gradient-to-t from-neutral-100 to-transparent"
    />
  </div>
</template>

<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query"

import { StockData } from "@finq/stocks/types"

type StockWithLockedType = StockData | { locked: true }

const props = defineProps<{
  class?: ClassValue
}>()

const { isIntersected, containerRef } = useIsIntersected()

const { data, isFetched } = useQuery({
  enabled: isIntersected,
  queryKey: ["homepage.stocksFINQFull"],
  queryFn: ({ signal }) => StocksService.getStocks({ signal, params: { size: 4 } }),
  select: (selectData) => {
    return (selectData?.data?.length <= 3
      ? [...selectData?.data, { locked: true }]
      : selectData?.data) as unknown as StockWithLockedType[]
  },

  placeholderData: () => {
    return {
      data: Array.from({ length: 3 }, (_, i) => ({
        asset: {} as any,
        rank: i + 1,
      })),
    } as unknown as PaginationResponse<StockData[]>
  },
})

const mappedStocks = computed(() => {
  return data.value?.map((stock, index) => {
    if ("locked" in stock) {
      return {
        id: null,
        locked: true,
        rank: index + 1,
        title: `Uncover #${index + 1}`,
        subtitle: "In FINQ's top list",
        logo: "assets/icons/lock-filled.svg",
      }
    }

    return {
      id: stock.id,
      locked: false,
      rank: stock.rankOrder,
      title: stock.symbol,
      subtitle: stock.description,
      logo: stock.logo,
      priceChange: stock.priceChange,
      allocation: stock.price, // TODO)) missing allocation value
    }
  })
})

const cellsFlex = {
  rank: "0 0 0%",
  name: "1",
  performance: "0 0 50px",
}
</script>

<style lang="scss" scoped>
.fade-hide {
  position: absolute;
  inset-inline: 0;
  bottom: 0;
  height: 64px;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(250, 250, 250, 0) 0%,
    rgba(250, 250, 250, 0.4) 30%,
    rgba(250, 250, 250, 0.65) 50%,
    rgba(250, 250, 250, 0.85) 70%,
    rgba(250, 250, 250, 0.95) 85%,
    #fafafa 100%
  );
}
</style>
